<template>
  <div>
    <div>
      <vs-table
        search
        stripe
        border
        description
        :sst="true"
        :data="table.data"
        :max-items="table.length"
        :total="table.total"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
      >
        <template slot="header">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="
                p-3
                border border-solid
                d-theme-border-grey-light
                rounded-full
                d-theme-dark-bg
                cursor-pointer
                flex
                items-center
                justify-between
                font-medium
              "
            >
              <span class="mr-2"
                >{{ this.table.start }} - {{ this.table.end }} of
                {{ this.table.total }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item
                v-for="item in table.limits"
                :key="item"
                @click="handleChangelength(item)"
              >
                <span>{{ item }}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
          <div class="btn-group ml-5">
            <vs-button
              size="small"
              color="success"
              type="border"
              @click="handleExport('excel')"
              >Excel</vs-button
            >
          </div>
        </template>
        <template slot="thead">
          <!-- <vs-th></vs-th> -->
          <vs-th
            v-for="(head, index) in this.table.heads"
            :key="index"
            :sort-key="head.field"
            >{{ head.title }}</vs-th
          >
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <!-- <vs-td> </vs-td> -->
            <vs-td
              v-for="(head, index) in table.heads"
              :key="index"
              :data="data[indextr][head.field]"
              >{{ data[indextr][head.field] }}</vs-td
            >
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination
        style="padding-top: 5px"
        :total="table.totalPage"
        v-model="setPage"
      />
    </div>
  </div>
</template>
<script>
// import { AgGridVue } from "ag-grid-vue";
// import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import moment from "moment";
export default {
  components: {
    // AgGridVue,
  },
  props: {
    baseUrl: {
      type: String,
    },
    territoryIDs: {
      type: String,
    },
    supplierIDs: {
      type: String,
    },
    status: {
      type: String,
    },
    keyDate: {
      type: String,
    },
    draw: {
      type: Number,
    },
    startDate: {
      type: Date
    },
    endDate: {
      type: Date
    },
  },
  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState(),
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
        heads: [
          {
            field: "code",
            title: "Code",
          },
          {
            field: "code_internal",
            title: "Code Internal",
          },
          {
            field: "invoice_date",
            title: "Invoice Date",
          },
          {
            field: "reference_purchase_code",
            title: "Reference Purchase Code",
          },
          {
            field: "po_date",
            title: "PO Date",
          },
          {
            field: "posting_date",
            title: "Posting Date",
          },
          {
            field: "territory_code",
            title: "Territory Code",
          },
          {
            field: "territory_name",
            title: "Territory Name",
          },
          {
            field: "supplier_code",
            title: "Supplier Code",
          },
          {
            field: "supplier_name",
            title: "Supplier Name",
          },
          {
            field: "net",
            title: "Net",
          },
          {
            field: "tax_value_invoice",
            title: "Tax Value Invoice",
          },
          {
            field: "wh_tax_value",
            title: "WH Tax Value",
          },
          {
            field: "total",
            title: "Total",
          },
          {
            field: "payment_value",
            title: "Payment Value",
          },
          {
            field: "outstanding_value_invoice",
            title: "Outstanding Value Invoice",
          },
          {
            field: "payment_document",
            title: "Payment Document",
          },
          {
            field: "payment_date",
            title: "Payment Date",
          },
          {
            field: "reference_code",
            title: "Reference Code",
          },
          {
            field: "status",
            title: "Status",
          },
          {
            field: "tax_number",
            title: "Tax Number",
          },
          {
            field: "tax_date",
            title: "Tax Date",
          },
          {
            field: "due_date",
            title: "Due Date",
          },
          {
            field: "username",
            title: "User Name",
          },
          {
            field: "scan_date",
            title: "Scan Date",
          },
          {
            field: "description",
            title: "Description",
          },
          {
            field: "created_at",
            title: "Created At",
          },
        ],
      };
    },
    formatCurrency(value) {
      return new Intl.NumberFormat("id-ID", {
        style: "decimal",
        minimumFractionDigits: 0
      }).format(value);
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    getData() {
      if (this.draw > 0) {
        this.$vs.loading();
        this.$http
          .get(this.baseUrl + "/table", {
            params: {
              length: this.table.length,
              page: this.table.page,
              search: this.table.search,
              order: this.table.order,
              sort: this.table.sort,
              territory_ids: this.territoryIDs,
              supplier_ids: this.supplierIDs,
              status: this.status,
              key_date: this.keyDate == "" ? "" : moment(this.keyDate).format("YYYY-MM-DD"),
              start_date: this.startDate
                ? moment(this.startDate).format("YYYY-MM-DD")
                : null,
              end_date: this.endDate
                ? moment(this.endDate).format("YYYY-MM-DD")
                : null
            },
          })
          .then((resp) => {
            this.tableDefaultState();
            if (resp.status === "success") {
              this.table.total = resp.data.total_record;
              this.table.totalPage = resp.data.total_page;
              this.table.totalSearch = resp.data.total_record_search;
              this.table.length = resp.data.total_record_per_page;
              this.table.data = resp.data.records.map(record => {
                return {
                  ...record,
                  invoice_date: record.invoice_date ? moment(record.invoice_date).format("YYYY-MM-DD") : '',
                  po_date: record.po_date ? moment(record.po_date).format("YYYY-MM-DD") : '',
                  tax_date: record.tax_date ? moment(record.tax_date).format("YYYY-MM-DD") : '',
                  due_date: record.due_date ? moment(record.due_date).format("YYYY-MM-DD") : '',
                  scan_date: record.scan_date ? moment(record.scan_date).format("YYYY-MM-DD") : '',
                  posting_date: record.posting_date ? moment(record.posting_date).format("YYYY-MM-DD") : '',
                  created_at: record.created_at ? moment(record.created_at).format("YYYY-MM-DD") : '',
                  tax_value_invoice: this.formatCurrency(record.tax_value_invoice),
                  net: this.formatCurrency(record.net),
                  wh_tax_value: this.formatCurrency(record.wh_tax_value),
                  total: this.formatCurrency(record.total),
                  payment_value: this.formatCurrency(record.payment_value),
                  outstanding_value_invoice: this.formatCurrency(record.outstanding_value_invoice),
                };
              });
              this.setStartEnd();
              this.$vs.loading.close();
            } else {
              this.table.total = 0;
              this.table.totalPage = 0;
              this.table.totalSearch = 0;
              this.table.length = 0;
              this.table.data =     [];
              this.setStartEnd();
              this.$vs.loading.close();
            }
            this.$forceUpdate()
          });
      }
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    dateFormat(val) {
      return moment(val).format("DD MMM YYYY");
    },
    handleExport(file) {
      this.$vs.loading();
      var filetitle =
        "AP_REPORT_"+moment().format("YYYYMMDDHHmmss");
      this.$http
        .get(this.baseUrl + "/export", {
          params: {
            file: file,
            title: filetitle,
            territory_ids: this.territoryIDs,
            supplier_ids: this.supplierIDs,
            status: this.status,
            key_date: this.keyDate == "" ? "" : moment(this.keyDate).format("YYYY-MM-DD"),
            start_date: this.startDate
              ? moment(this.startDate).format("YYYY-MM-DD")
              : null,
            end_date: this.endDate
              ? moment(this.endDate).format("YYYY-MM-DD")
              : null
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.status == "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              color: "success",
              title: "Processing",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        });
    },
  },
  watch: {
    detail() {
      this.getData();
    },
    draw(val) {
      if (val > 0) {
        this.getData();
      }
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>
